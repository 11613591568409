import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import config from '../config.js';
import './AdminDashboard.css';

const products = [
  'Quality Gate',
  'ACC Project Service',
  'Download Portal General Access',
  'QTO Booster 6',
  'QTO Booster 7',
  'QTO Booster 8',
  'Bim Booster'
];

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [userLicenses, setUserLicenses] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const response = await fetch(`${config.api.invokeUrl}/Users`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const usersData = await response.json();
        //console.log('Fetched users:', usersData);

        setUsers(usersData.sort((a, b) => a.email.localeCompare(b.email))); // Sort users alphabetically
      } catch (error) {
        console.error('Error fetching users', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchAllUserLicenses = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const licensesPromises = users.map(user =>
          fetch(`${config.api.invokeUrl}/UserLicenses?email=${encodeURIComponent(user.email)}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        );

        const licensesResponses = await Promise.all(licensesPromises);
        const licensesData = await Promise.all(licensesResponses.map(res => res.json()));
        const licensesObject = users.reduce((acc, user, index) => {
          const licensesArray = Array.isArray(licensesData[index].licenses) ? licensesData[index].licenses : [];
          acc[user.email] = {
            licenses: licensesArray.reduce((licensesAcc, license) => {
              licensesAcc[license] = true;
              return licensesAcc;
            }, {}),
            //isAdmin: user.groups.includes('Download-Portal-Admins')
            isAdmin: user.groups.includes('Download-Portal-Admins')
          };
          return acc;
        }, {});

        setUserLicenses(licensesObject);
      } catch (error) {
        console.error('Error fetching user licenses', error);
      }
    };

    if (users.length > 0) {
      fetchAllUserLicenses();
    }
  }, [users]);

  const handleLicenseChange = (email, product) => {
    setUserLicenses(prevLicenses => ({
      ...prevLicenses,
      [email]: {
        ...prevLicenses[email],
        licenses: {
          ...prevLicenses[email].licenses,
          [product]: !prevLicenses[email].licenses[product]
        }
      }
    }));
  };

  const handleAdminChange = async (email, isChecked) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
  
      const action = isChecked ? 'add' : 'remove';
      const response = await fetch(`${config.api.invokeUrl}/UserGroup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email, action, group: 'Download-Portal-Admins' })
      });
  
      if (!response.ok) {
        const errorMessage = await response.json();
        console.error('Error response from server:', errorMessage);
        throw new Error(`Failed to update user group: ${errorMessage.message}`);
      }
  
      setUserLicenses(prevLicenses => ({
        ...prevLicenses,
        [email]: {
          ...prevLicenses[email],
          isAdmin: isChecked
        }
      }));
    } catch (error) {
      console.error('Error updating user group membership', error);
    }
  };

  const saveLicenses = async (email) => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const { licenses, isAdmin } = userLicenses[email];
    const licensesArray = Object.keys(licenses).filter(key => licenses[key]);

    const response = await fetch(`${config.api.invokeUrl}/UserLicenses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ email, licenses: licensesArray, isAdmin })
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Error response from server:', errorResponse);
      throw new Error('Failed to save licenses');
    }

    alert('Licenses updated successfully');
  } catch (error) {
    console.error('Error updating licenses', error);
  }
};

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUserClick = (email) => {
    setSelectedUser(selectedUser === email ? '' : email);
  };

  const filteredUsers = users.filter(user => user.email.toLowerCase().includes(searchQuery.toLowerCase()));
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="admin-container">
      <h1 className="admin-heading">Admin Dashboard</h1>
      <p className="admin-subheading">Only accessible to Super Admins</p>
      <button className="admin-button" onClick={handleLogout}>Logout</button>
      <div>
        <input
          type="text"
          className="admin-search"
          placeholder="Search users..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Emails</th>
                {products.map(product => (
                  <th key={product}>{product}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user.email} onClick={() => handleUserClick(user.email)}>
                  <td className="email-cell">{user.email}</td>
                  {products.map(product => (
                    <td key={`${product}-${user.email}`}>
                      {product === 'Download Portal General Access' ? (
                        <>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={userLicenses[user.email]?.licenses[product] || false}
                              onChange={() => handleLicenseChange(user.email, product)}
                              disabled
                            />
                            <span className="slider"></span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            Admin&nbsp;
                            <input
                              type="checkbox"
                              checked={userLicenses[user.email]?.isAdmin || false}
                              onChange={(e) => handleAdminChange(user.email, e.target.checked)}
                              disabled
                            />
                          </label>
                        </>
                      ) : (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={userLicenses[user.email]?.licenses[product] || false}
                            onChange={() => handleLicenseChange(user.email, product)}
                            disabled
                          />
                          <span className="slider"></span>
                        </label>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`main-dashboard-overlay ${selectedUser ? 'active' : ''}`} onClick={() => setSelectedUser('')}></div>
        {selectedUser && (
          <div className={`flyout-panel ${selectedUser ? 'open' : ''}`}>
            <h3>{selectedUser}</h3>
            {products.map(product => (
              <div key={product} className="switch-container">
                <label>{product}</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={userLicenses[selectedUser]?.licenses[product] || false}
                    onChange={() => handleLicenseChange(selectedUser, product)}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            ))}
            <div className="switch-container">
              <label>Admin General Access</label>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={userLicenses[selectedUser]?.isAdmin || false}
                  onChange={(e) => handleAdminChange(selectedUser, e.target.checked)}
                />
                <span className="slider"></span>
              </label>
            </div>
            <button className="admin-button" onClick={() => saveLicenses(selectedUser)}>Save Licenses</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
